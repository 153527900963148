<app-carousel> </app-carousel>

<div class="wrapper" [ngClass.xs]="{ xs: true }">
  <ng-container
    *ngIf="
      init && (isOnline$ | async) !== null && (isOnline$ | async) === false
    "
  >
    <app-offline-message></app-offline-message>
  </ng-container>

  <h2
    class="mat-headline"
    *ngIf="mediaObserver.isActive('lt-md') || isTablet"
    style="text-align: center; margin-top: 25px"
  >
    <button
      style="text-align: center; margin: 20px"
      (click)="openMenu()"
      aria-label="Открыть боковое меню"
      mat-raised-button
      color="primary"
      style="padding: 10px; font-size: 1.5rem"
    >
      Открыть меню
    </button>
  </h2>

  <app-rolls-of-day></app-rolls-of-day>

  <h2 class="mat-headline" style="text-align: center; margin: 25px 0">
    <button
      routerLink="/discounts"
      aria-label="Открыть сраницу с акциями и скидками"
      mat-raised-button
      color="primary"
      style="font-size: 1.5rem; padding: 10px 25px"
    >
      Наши акции и скидки
    </button>
  </h2>
  <app-info-features></app-info-features>

  <div class="terms">
    <h3>Условия доставки:</h3>
    <h4>Время доставки: 11:00 - 22:45</h4>

    <p>К КАЖДОМУ ЗАКАЗУ, кроме самовывоза, ПЛЮС доставка 50 РУБЛЕЙ!</p>

    <p>
      Друзья! При заказе впервые мы просим предоплату в размере 300 рублей.
      Спасибо за понимание <span class="color-red">❤</span>
    </p>

    <strong>Минимальная доставка:</strong>
    <p>По городу &mdash; от {{ minOrderSum }} руб</p>
    <p>Лесозавод, Кочпон Чит &mdash; от 1500 руб</p>
    <p>Выльгорт &mdash; от 1500 руб</p>
    <p>
      Частный сектор Выльгорт от стадиона( Родниковая, Совхозная, 70 лет Победы,
      Гилева, Шаньгиной, Мальцевой, Бирюзовая, Изумрудная, Березовая, Дальняя,
      Хвойная, Энтузиастов) &mdash; от 2500 рублей
    </p>
    <p>70 лет Победы &mdash; от 2500 руб</p>
    <p>Еля ты &mdash; от 2000 руб</p>
    <p>Пичипашня &mdash; 2000 руб</p>
    <p>Ближняя Орбита &mdash; от 800 руб - до Ветеранов 7.</p>
    <p>
      Дальняя Орбита &mdash; от 1000 руб. (Тентюковская 304-330, Октябрьский пр
      200,212,214,222)
    </p>
    <p>
      Дор участок &mdash; от 1500 руб. (Тентюковская
      332,151/1,151,453,451,455,455/1,461,144,146,148,465,150,152,154,469,162,160,166
      ,168, 172,170,176,178,186. Тентюковская 475, 473,479,501,499,481,483,503.
      Октябрьский 375,378,380,370. Проезд Геологов. Нагорная)
    </p>
    <p>Дачи от пива до бензиновой заправки &mdash; от 1000 руб.</p>
    <p>
      Дачи от бензиновой заправки до Нивы &mdash; от 2000 руб(Север, Березка,
      Черемушки, 1ое Сыктывкарское, Верба, Дружба, Рябина, Ручеек и тд)
    </p>
    <p>Дачи от Нивы до объездной &mdash; от 3000 руб.</p>
    <p>Вурдысь &mdash; от 3000 руб.</p>
    <p>&nbsp;</p>
    <strong
      >Районы, указанные ниже принимаем только до 16.00 и после 20.00!!!</strong
    >
    <p>Нижний Чов &mdash; от 2000 руб</p>
    <p>Верхний Чов &mdash; от 2000 руб</p>
    <p>Лесозавод &mdash; от 1500 руб</p>
    <p>70 лет Победы &mdash; от 2500 руб</p>
    <p>Еля ты &mdash; от 2000 руб</p>
    <p>Пичипашня &mdash; от 2000 руб</p>
    <p>Эжва &mdash; от 2500 руб</p>
    <p>Краснозатонский район &mdash; от 2500 руб</p>
    <p>Эжва Строитель &mdash; от 3000 руб</p>
    <p>Максаковка &mdash; от 3000 руб</p>
    <p>Яг Кар &mdash; от 3000 руб</p>
    <p>Дачи от Нивы до объездной &mdash; от 3000 руб</p>
    <p>К каждому заказу плюс доставка 50 руб</p>
    <p>&nbsp;</p>
    <strong>Платная доставка:</strong>
    <p>По городу &mdash; 200 руб</p>
    <p>Ближняя/Дальняя Орбита &mdash; 250 руб.</p>
    <p>Дор участок &mdash; 300 руб.</p>
    <p>Лесозавод, Кочпон Чит &mdash; 300 руб</p>
    <p>Выльгорт &mdash; 300 руб</p>
    <p>Нижний и Верхний Чов &mdash; 300 руб</p>
    <p>Краснозатонский, Эжва, Максаковка &mdash; 500 руб</p>
  </div>
</div>
